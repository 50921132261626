a{
    color: white;
    text-decoration: none;
    display: inline-block;
}
a:hover{
    color: yellow;
    text-decoration: none;
}
.link-no-underline {
  text-decoration: none;
  color: white;
}
.link-no-underline:hover{
  color: yellow;
}
.page_header{
    top: 0;
    height: 12vh;
    position: fixed;
    width: 100%;
    background-color: rgb(52, 128, 226);
    display: flex;
    z-index: 100;
}
.main_header{
    top: 0;
    height: 12vh;
    position: absolute;
    width: 100%;
    display: flex;
    z-index: 100;
    background-color: transparent;  
}
.header_logo{
    object-fit: contain;
    margin: 12px 70px;
    margin-right: 40px;
    color: black;
}
.header_nav{
    color: white;
    /* color: black; */
    margin-left: 30px;    
    display: flex;
    align-items:center;
}
.header_option{
    margin: 25px;
    font-weight: 800;
    text-decoration: none;
}
.header_option > a > p{
    margin-top: 16px;
}
.header_contact{
    margin: 35px;
}
.header_contact > a{
  color: white;
}
.header_contact > a:hover{
  color: yellow;
}
#call:hover > ul{
    display: block;
}
ul {
    position: absolute;
    right: 280px;
    top: 55px;
    width: 130px;
    height: 120px;
    border-radius: 5px;
    display: none;
  }
ul > li{ 
    background-color: rgb(255, 255, 255); 
    color: black;
    display: block; 
  }



  @media only screen and (max-width: 769px) {
    /* Styles for screens smaller than 768px (e.g., tablets and mobile devices) */
    
    .header_logo {
      margin: 7px 7px;
      margin-right: 0px;
    }
  
    .header_nav {
      margin-left: 0px;
      color: white;
    }
    .header_option {
      margin-right: 4px;
      font-size: 12px;
      color: white;
    }
  
    .header_contact {
      margin-right: 6px;
      color: white;
    }
  
    #call:hover > ul {
      right: 130px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    /* Styles for screens smaller than 480px (e.g., mobile devices) */
  
    .header_logo {
      margin: 5px 5px;
      margin-right: 0px;
      max-width: 70px;
      max-height: 80px;
    }
    .header_nav {
      margin-left: 0px;
    }
  
    .header_option {
      margin: 5px;
      font-size: 10px;
    }
  
    .header_contact {
      margin: 5px;
      max-height: min-content;
    }
  
    #call:hover > ul {
      right: 90px;
    }
  }