
body{
    background-color: rgb(6, 12, 61);
}
h2,h3{
    font-weight: 900;
    color: rgb(51, 51, 139);
    text-transform: capitalize;
}
.about > h2{
    font-weight: 600;
    color: rgb(6, 12, 61);
}
.overview_content{
    /* justify-content: center; */
    align-items: center;
}
.overview_heading{
    position: relative;
    background-color: rgb(6, 12, 61);
    height: 82vh;
    width: 100%;
}
.overview_heading > h1{
    color: white;
    padding: 90px;
}
.center {
    position: absolute;
    top: 30vh;
    left: 25%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
.overview_img > img{
    max-height: 70vh;
    width: 50%;
}
.overview_text{
    font-size: larger;
    font-weight: 600;
    margin: auto;
    padding: 20px 60px;
    text-align: left;
}
.overview_text > h3{
    font-weight: 900;
    text-align: left;
    color: rgb(51, 51, 139);
    margin-top: 20px;
}
.cardd{
    position: absolute;
    background-color: white;
    width: 80%;
    height: 110vh;
    top: 30vh;
    left: 10%;
    box-shadow: 0 0 10px 8px rgb(57, 161, 196);
}

.teachers{
    margin-top: 64vh;
    /* background-color: aqua; */
}
.teachers > h2{
    font-weight: 900;
    color: rgb(51, 51, 139);
    /* color: black; */
}
.photos > button{
    background-color: rgb(35, 35, 240);
    /* height: 30px; */
    color: white;
    margin-top: 20px;
    padding: 11px 20px;
    border-radius: 25px;
    border: none;
    font-weight: 800;
}
.photos > button:hover{
    cursor: pointer;
    box-shadow: 0px 8px 6px -6px black;
}
.teacher_option{
    width: 80%;
    margin: 50px auto;
    display: flex;
    /* margin-bottom: 0px;   */
    padding-bottom: 0px;
    box-shadow: 0 0 10px 8px rgb(57, 161, 196);
    box-shadow: 0 0 10px 8px rgb(6, 12, 61);
    /* background-color: white; */
}
.p{
    background-color: rgb(6, 12, 61);
    position: relative;
    top: -100px;
    height: 72.5vh;
    width: 25vw;
    margin-bottom: 0px;
    padding-bottom: 0px;
    /* transform: translateY(10%); */
}
.p > p{
    padding-top: 150px;
    /* color: black; */
    font-weight: 900;
    font-size: x-large;
    color: white;
}
.teacher_left{
    margin-bottom: 0px;
    padding-bottom: 0px;
    object-fit: contain;
    max-height: 89vh;
}
.teacher_left > img{
    border: 2px solid rgb(23, 46, 76);
    border-radius: 50%;
    width: 15vw;
    height: 35vh;
    z-index: 10;
    position: relative;
    top: 0px;
}
.teacher_right{
    width: 750vh;
    text-align: left;
    margin: auto;
    margin-left: 70px;
    padding: 55px 30px;
    padding-bottom: 0px;
    /* background-image: linear-gradient(to right,rgb(52, 128, 226),white); */
}
.teacher_right > h2{
    font-weight: 900;
    color: rgb(51, 51, 139);
    /* color: black; */
}
.teacher_right > p{
    font-weight: 900;
    margin-top: 20px;
    color: black;
}
.section_2 > h2{
    font-weight: 800;
    color: rgb(51, 51, 139);
    margin: 50px;
}
.teachers_section{
    /* background-image: linear-gradient(
    to right,
    rgb(35, 13, 197),
    rgb(10, 10, 76)
  ); */
  background-color: rgb(6, 12, 61);
  margin-bottom: 50px;
}
.card-decks {
    padding-top: 80px;
    padding-bottom: 80px;
}
.card-deck{
    padding: 20px;
    display: flex;
    
}
.card-img-top{
    height: 35vh
}
.card{
    height: 80vh;
    width: 30vw;
    margin: auto;
}
.card-title{
    color: rgb(51, 51, 139);
    font-weight: 700;
}
.photos > h2{
    font-weight: 900;
    color: rgb(51, 51, 139);
    margin-top: 0px;
    margin-bottom: 60px;
    /* color: black; */
}
.photos > button{
    margin: 30px;
}
.details > h2, details > h3{
    font-weight: 900;
    color: rgb(51, 51, 139);
    margin-top: 0px;
    margin-bottom: 60px;
    color: black;
}
.map_img{
    margin: 30px ;
    height: 60vh;
}
.map_img > iframe{
    width: 70%;
    height: 60vh;
}
.map > h3{
    margin: 20px;
    font-size: medium;
    color: black;
    color: black;
    margin: 40px;
}
.contact_options{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    object-fit: fill;
}
.ab{
    margin: 50px;
}
.col > a, .col-4 > a{
    color: black;
    color: black;
} 
.col > a:hover, .col-4 > a:hover{
    color: yellow;
}



/* Media Queries */

@media screen and (max-width: 769px) {
    .overview_heading {
      font-size: 20px;
    }
  
    .cardd {
        height: 180vh;
    }

    .teachers{
        margin-top: 135vh;
    }

    .p{
        height: 93.5vh;
    }

    .card-deck {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    
      .card {
        height: auto;
        width: 80%;
        margin-bottom: 40px;
      }
  }
  
  @media screen and (max-width: 480px) {
    .overview_heading {
        font-size: 10px;
        height: 40vh;
      }
    
      .cardd {
        top: 24vh;
        margin-top: 0px;
        height: 83vh;
        display: flex;
        justify-content: center;
      }
      .overview_text{
        font-size: 10px;
        padding: 15px;
      }
    
      .teachers {
        margin-top: 70vh;
      }
      .teacher_option{
        height: 90vh;
        display: flex;
      }
      .teacher_left{
        display: flex;
        flex-direction: column;
      }
      .teacher_left > img{
        height: 16vh;
        width: 28vw;
      }
      .teacher_right > p{
        font-size: 10px;
      }
      .p {
        /* position: relative; */
        /* height: 90.5vh; */
         /* Hide content that exceeds the boundaries */
        height: 150%; 
        max-height: 100vh;
        overflow-y: hidden;
        /* padding-top: 0px;
        margin-top: 0px; */
        top: -60px;    
      }    

      .ab{
        margin: 0px;
      }
      .col>h3, .col>p{
        margin-top: 7px;
        font-size: 11px;
      }
      .teacher_right{
        margin-left: 0px;
        padding: 5px;
        font-size: smaller;
      }
      .teacher_right > h2{
        margin-top: 60px;
      }
    
      .card-deck {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    
      .card {
        height: auto;
        width: 90%;
        margin-bottom: 30px;
      }
      .card-deck{
        margin-top: -60px;
        margin-bottom: 0px;
      }
      .card-deck > .card{
        height: 40vh;
      }
      .card-deck > .card > img{
        height: 17vh;
      }
      .card-title{
        font-size: large;
      }
      .card-text{
        font-size: small;
      }
  }