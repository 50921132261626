@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600&family=Piazzolla:opsz,wght@8..30,200&family=Roboto:wght@100;400;700&display=swap');

*{
  margin: 0px;
  padding: 0px;
  /* font-family: 'Open Sans', sans-serif; */
  
  font-family: 'Roboto', sans-serif;
}

.App {
  text-align: center;
  background-color: rgb(253, 249, 249);
}

