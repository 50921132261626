.footer{
    position: absolute;
    min-height: 70vh;
    background-color: rgb(52, 128, 226);
    margin-top: 20px;
    margin-bottom: 0px;
}
.footer > p{
    color: white;
    font-size: small;
    padding: 30px;
    margin: 30px;
    margin-bottom: 0px;
    text-align: left;
}
.links{
    display: flex;
    margin-right: 105px;
}
.footer_contact{
    margin: 0px 15px;
}
.footer_contact > a{
  color: white;
}
.footer_contact > a:hover{
  color: yellow;
}
span{
    margin-left: 42px;
}
.white-icon {
  color: white !important;
}
.text-reset{
  text-decoration: none;
}



@media only screen and (max-width: 769px) {
    /* Styles for screens smaller than 768px (e.g., tablets and mobile devices) */
  
    .footer > p {
      padding: 15px;
      margin: 15px;
      text-align: center;
    }
  
    .links {
      justify-content: center;
      margin-right: 0;
    }
  
    .footer_contact {
      margin: 0 10px;
    }
  
    span {
      margin-left: 20px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    /* Styles for screens smaller than 480px (e.g., mobile devices) */
  
    .footer {
      max-height: max-content;
    }
  
    .footer > p {
      padding: 10px;
      margin: 10px;
    }
  
    span {
      margin-left: 10px;
    }
  
    .footer_contact {
      margin: 0 5px;
    }
  }