a{
  text-decoration: none;
}
*{
  margin: 0px;
  padding: 0px;
}
body {
  margin: 0;
  /* font-family: 'Open Sans', sans-serif; */
  /* font-family: 'Piazzolla', serif; */
  /* font-family: 'Roboto', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
