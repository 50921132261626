/* .gallery{
   margin-top: 50px;
   padding: 20px;
}
.gallery > img{
    transition: transform .2s;
}
.gallery > img:hover{
    transform: scale(1.5);
    z-index: 10;
}
.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create four equal columns that sits next to each other */
  /* .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  } */
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  /* @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  /* @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  } */
  .gal{
    /* background-color: blue; */
    margin-top: 100px;
    /* background-image: aqua; */
  }
  .gal_heading > h2{
      /* background-color: blue; */
      /* color: rgb(32, 32, 129); */
      margin-top: 125px;
      color: rgb(5, 5, 66);
      
  }
  .gal_heading{
    position: absolute;
    background-image: linear-gradient(to bottom, rgb(3, 41, 98),white);
    /* height: 82vh; */
    top: 0px;
    height: 35vh;
    width: 100%;
}
  .gallery{
     /* margin-top: 50px; */
     padding: 20px;
  }
  .column > img{
      transition: transform .2s ease;
  }
  .column > img:hover{
      transform: scale(1.8);
      z-index: 10;
  }
  .c > img:hover{
      transform: scale(1.8) translateX(-100px);
  }
  .a > img:hover{
    transform: scale(1.8) translateX(100px);
  }
  .row {
      display: flex;
      flex-wrap: wrap;
      padding: 0 4px;
    }
    
    /* Create 3 equal columns that sits next to each other */
    .column {
      flex: 33.33%;
      max-width: 33.33%;
      padding: 0 4px;
    } 
   
    
    .column img {
      margin-top: 8px;
      vertical-align: middle;
      width: 100%;
    }
  
    @media only screen and (max-width: 480px) {
      .c > img:hover{
        transform: scale(1.8) translateX(-30px);
    }
    .a > img:hover{
      transform: scale(1.8) translateX(30px);
    }
    }