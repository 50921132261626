.reviews{
  margin-top: 180px;
}
.reviews > h2{
    color: rgb(32, 79, 129)
}
.gallery{
   /* margin-top: 50px; */
   padding: 20px;
}
.column > img{
    transition: transform .2s ease;
}
.column > img:hover{
    transform: scale(1.8);
    z-index: 10;
}
.c > img:hover{
    transform: scale(1.8) translateX(-100px);
}
.a > img:hover{
  transform: scale(1.8) translateX(100px);
}
.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
  /* Create 3 equal columns that sits next to each other */
  .column {
    flex: 33.33%;
    max-width: 33.33%;
    padding: 0 4px;
  } 
 
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
  .gallery > .row {
    flex-direction: column;
  }

  .column {
    flex: 100%; /* Each column takes up 100% of the width */
    max-width: 100%;
  }
  /* Reset hover effect */
  .column > img:hover,
  .column > img:active,
  .column > img:focus {
    transform: none !important; /* Reset the transform property */
    z-index: auto !important; /* Reset the z-index property if needed */
  }
  .gal_heading{
    max-height: 20vh;
  }

  }
